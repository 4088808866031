.App {
  @apply min-h-screen;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

// gleap styles
@media screen and (max-width: 768px) {
  .App {
    @apply mb-14 min-h-0;
  }
  
  .bb-feedback-button {
    bottom: 60px !important;
  }
}

.bb-feedback-button-icon {
  width: 40px !important;
  height: 40px !important;
}

.bb-logo-logo {
  width: 60% !important;
  height: 60% !important;
  top: 8px !important;
  left: 8px !important;
}

.bb-logo-arrowdown {
  width: 45% !important;
  height: 45% !important;
  top: 11px !important;
  left: 11px !important;
}

.bb-feedback-button-icon path,
.bb-capture-dismiss path {
  fill: #fff !important;
}

.bb-capture-button-next {
  color: #fff !important;
}

@media (min-width: 768px) {
  .bb-logo-logo {
    width: 38px !important;
    height: 38px !important;
    top: 11px !important;
    left: 11px !important;
  }

  .bb-logo-arrowdown {
    width: 35% !important;
    height: 35% !important;
    top: 20px !important;
    left: 20px !important;
  }

  .bb-feedback-button-icon {
    width: 60px !important;
    height: 60px !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
