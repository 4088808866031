@import './styles/abstracts/variables';
@import './styles/abstracts/animations';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/base/typography';
@import './styles/base/button';
@import './styles/base/forms';

// Components
@import './styles/components/ReactModal.scss';

body {
  @apply text-gray-800 bg-gray-100;

  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Arial', monospace;
}
