select {
  padding: 0.2rem 0.5rem;
  background: transparent;
  border-bottom: var(--border-default);
  border-radius: 0;
}

.invalid-feedback {
  @apply text-error;
}
