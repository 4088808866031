// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

:root {
  // Color system
  --white: #fff;
  --gray-100: #fbf5e7;
  --gray-150: #f5f3f0;
  --gray-200: #e3ded0;
  --gray-300: #cbc6b8;
  --gray-400: #b4afa1;
  --gray-500: #9c988a;
  --gray-600: #848072;
  --gray-700: #6d695b;
  --gray-800: #555143;
  --gray-900: #3d3a2c;
  --gray-1000: #3f3b2c;
  --black: #1f1d16;
  // brand colors
  --brand-100: #ffc65b;
  --brand-200: #ffb662;
  --brand-300: #fea66a;
  --brand-400: #fe9771;
  --brand-500: #fd8779;
  --brand-600: #fd7780;
  --color-primary: var(--brand-400);
  --color-secondary: var(--brand-600);
  // utility colors
  --color-error: var(--brand-600);
  --color-success: #76d581;
  --color-warning: #FFC65B;
  // 3rd party colors
  --color-facebook: #3b5998;
  --color-instagram: #E1306C;
  // components
  --main-logo-color: var(--color-primary);
  // border
  --border-default: 1px solid var(--gray-300);
  --border-light: 1px solid var(--gray-200);
}
