%heading {
  @apply my-0 mb-2 font-semibold;
}

h1 {
  @extend %heading;

  @apply text-4xl font-extrabold;
}

h2 {
  @extend %heading;

  @apply text-2xl font-extrabold;
}

h3 {
  @extend %heading;

  @apply text-lg font-bold;
}

h4 {
  @extend %heading;

  @apply text-sm font-semibold;
}
