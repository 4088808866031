.pageLoader {
  @apply bg-gray-100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &Logo {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: clamp(100px, 12vmax, 200px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}
